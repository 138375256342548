import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    private toshow = true;
    private timer = false;
    public shownotpage: boolean = false;
	private user : any;
    constructor(public location: Location, private router: Router) {
    }

    ngOnInit() {

           this.user =  JSON.parse(localStorage.getItem('user'));
		console.log(this.user);

      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
           if (event.url != this.lastPoppedUrl)
               this.yScrollStack.push(window.scrollY);
       } else if (event instanceof NavigationEnd) {
           if (event.url == this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else
               window.scrollTo(0, 0);
       }
     });
     this.location.subscribe((ev:PopStateEvent) => {
         this.lastPoppedUrl = ev.url;
     });
     var title = this.location.prepareExternalUrl(this.location.path());

     if(title.indexOf('loginas') !== -1){

       this.toshow = false;
     }

    if(!this.user){
   this.shownotpage = true;

    }

     if(title === '#/login'){
       this.toshow = false;
     }else{

        this.toshow = true;
		 /*	if(!this.user){

			 this.router.navigate(['/login'])
    .then(() => {
      window.location.reload();
    })
  }*/
     }

     if(title === "#/examens"){
       this.timer = true;
     }
    }
goToPage(page){

  this.router.navigate(['/page',page])
  .then(() => {
    window.location.reload();
  })
}
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === '#/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '#/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }

    doLogout(){

      localStorage.removeItem('user');
      localStorage.clear();
      this.router.navigate(['/login'])
      .then(() => {
        window.location.reload();
      })
    }
}

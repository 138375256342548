import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { HttpClient } from "@angular/common/http";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
   private user : any;
     userData = {"email":"", "password":"","first_name":"","last_name":"","id":""};
    constructor(private router: Router, private httpClient: HttpClient) { }

    ngOnInit() {

		 this.user =  JSON.parse(localStorage.getItem('user'));
    this.userData = this.user;
	}
  doUpdate(){

    this.httpClient.post("/api/applications/doUpdate", this.userData ).subscribe(data =>{
            localStorage.setItem('user', JSON.stringify(data));

            this.router.navigate(['/home'])
     .then(() => {
       window.location.reload();
     })
          })
  }
}

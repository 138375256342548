import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Route, ActivatedRouteSnapshot, UrlSegment, Params, Data } from '@angular/router';

@Component({
    selector: 'app-loginas',
    templateUrl: './loginas.component.html',
    styleUrls: ['./loginas.component.scss']
})
export class LoginasComponent implements OnInit {
  private toshow = true;

  private myresults = [];
  private  hash: any;
    constructor(
    private router: Router,
    private httpClient: HttpClient,
    private route: ActivatedRoute
  ) {

    this.hash =  this.route.snapshot.params.hash;
    this.httpClient.get("/api/applications/loginas/"+this.hash+"").subscribe((data:any) =>{
      localStorage.removeItem('user');
      localStorage.clear();
      localStorage.setItem('user', JSON.stringify(data));


           this.router.navigate(['/home'])
        .then(() => {
          window.location.reload();
        })
         });
   }

    ngOnInit() {


    }


}

import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Route, ActivatedRouteSnapshot, UrlSegment, Params, Data } from '@angular/router';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
    selector: 'app-examen',
    templateUrl: './examen.component.html',
    styleUrls: ['./examen.component.scss']
})
export class ExamenComponent implements OnInit {
    test : Date = new Date();
    focus;
    focus1;
    focus2;
  private examData = {"reponse":""};
    private  shownormal=true;
    private showgood = false;
    private showwrong = false;
    private i=0;
    private timer;
    private class = "test";
    private classo = "test";
	private exams  = [];
	private id : any;
  	private user : any;
	private current = 1;
	private clicked = 0;


 isPlay: boolean = false;
    constructor(
	private spinner: NgxSpinnerService,
	private route: ActivatedRoute,
  private router: Router,
	private httpClient: HttpClient) {}


    ngOnInit() {

      let timerId = setInterval(() => this.incrument(), 1000);


     this.id =  this.route.snapshot.params.id;

     this.user =  JSON.parse(localStorage.getItem('user'));

      this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);

		this.httpClient.get("/api/applications/questions/"+this.id+"").subscribe(( data: any ) =>{

           this.exams = data;

			console.log(data);

         });
    }

    Click(id){

      let element:HTMLElement = document.getElementById('img'+id+'') as HTMLElement;

element.click();

    }
    handleChange(index){

     this.clicked = index;

  }
    open(index){

	}
 	goToNext(question,clicked,exam_id){

    this.httpClient.post("/api/applications/setreponse", { user_id: this.user.id, exam_id:exam_id, data:question,clicked:clicked}).subscribe(data =>{


          })

		this.current ++ ;
	}
  goToEnd(question,clicked,exam_id){
  this.httpClient.post("/api/applications/setreponse", { user_id: this.user.id, exam_id:exam_id, data:question,clicked:clicked,reponse:this.examData.reponse,timer:this.i}).subscribe(data =>{
 this.router.navigate(['/results',data]);

})




}

incrument(){

  this.i++;

}


}

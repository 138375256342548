import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Route, ActivatedRouteSnapshot, UrlSegment, Params, Data } from '@angular/router';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})

export class PageComponent implements OnInit {
  private toshow = true;

 private pages = [];



   private  page: any;
    model = {
        left: true,
        middle: false,
        right: false
    };



    focus;
    focus1;
   user = {};
    constructor(
      private router: Router,
      private translate: TranslateService,
      private httpClient: HttpClient,
      private route: ActivatedRoute
  ) {

  }

    ngOnInit() {
      this.page =  this.route.snapshot.params.page;

     this.httpClient.get("/api/applications/page/"+this.page+"").subscribe(( data: any ) =>{

            this.pages = data;

       console.log(data);

          });
    }


}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { HttpClient } from "@angular/common/http";

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;
  size = 0;
  all = 1;
  cat = "";
private uid = 0 ;
  private myresults : any;
  private users : any;
  pageOfItems: Array<any>;
  constructor(
      private router: Router,
      private spinner: NgxSpinnerService,
      private httpClient: HttpClient
  ) { }

  ngOnInit() {
	  setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);
    this.users =  JSON.parse(localStorage.getItem('user'));
    this.uid = this.users.id;
    this.httpClient.get("/api/applications/getreponse/"+this.uid+"").subscribe((donnes : any) =>{
           this.myresults = donnes;
           this.size = donnes.length;
           console.log(donnes);
    });


 }

  GetCatUsed(e){


    if(e.target.value == 'Toutes les catégories'){
        this.all = 1;
    }else{
  this.cat = e.target.value;
        this.all = 0;
    }

  }
  goToExams(id){
    this.router.navigate(['/results',id])
  }
  onChangePage(pageOfItems: Array<any>) {
     // update current page of items
     this.pageOfItems = pageOfItems;
 }

}

import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { CoursComponent } from './cours/cours.component';
import { ResultsComponent } from './results/results.component';
import { ProfileComponent } from './profile/profile.component';
import { ExamenComponent } from './examen/examen.component';
import { LandingComponent } from './landing/landing.component';
import { MyresultsComponent } from './myresults/myresults.component';
import { LoginComponent } from './login/login.component';
import { LoginasComponent } from './loginas/loginas.component';
import { PageComponent } from './page/page.component';
import { SectionsComponent } from './sections/sections.component';

const routes: Routes =[
    { path: 'home',             component: HomeComponent },
    { path: 'user-profile',     component: ProfileComponent },
    { path: 'examens/:id',          component: ExamenComponent },
    { path: 'mes-examens',      component: LandingComponent },
    { path: 'cours/:id',            component: CoursComponent },
    { path: 'page/:page',            component: PageComponent },
    { path: 'results/:id',          component: ResultsComponent },
    { path: 'loginas/:hash',          component: LoginasComponent },
    { path: 'resultat-examen/:id',  component: MyresultsComponent },
    { path: 'login',            component: LoginComponent },
    { path: 'section',            component: SectionsComponent },
    { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient } from "@angular/common/http";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  categories = [] ;
    focus;
    focus1;
    constructor(
    private router: Router,
    private translate: TranslateService,
    private httpClient: HttpClient
  ) {
translate.setDefaultLang('fr');
   }

    ngOnInit() {

      this.httpClient.get("/api/applications/categories").subscribe((data:any) =>{
           console.log(data);

           this.categories = data;
         })
    }

    goToExams(id){

      this.router.navigate(['/cours',id]);

    }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { ExamenComponent } from './examen/examen.component';
import { MyresultsComponent } from './myresults/myresults.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { ResultsComponent } from './results/results.component';
import { CoursComponent } from './cours/cours.component';
import { PageComponent } from './page/page.component';
import { HomeComponent } from './home/home.component';
import { LoginasComponent } from './loginas/loginas.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { JwPaginationComponent } from 'jw-angular-pagination';
import { NgxSpinnerModule } from "ngx-spinner";
import {CrystalLightboxModule} from '@crystalui/angular-lightbox';
import { CountdownTimerModule } from 'ngx-countdown-timer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatVideoModule } from 'mat-video';

@NgModule({
  declarations: [
    AppComponent,
    ExamenComponent,
    LandingComponent,
    MyresultsComponent,
    ProfileComponent,
    NavbarComponent,
    CoursComponent,
    LoginasComponent,
    PageComponent,
    ResultsComponent,
    FooterComponent,
    JwPaginationComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule,
	  CrystalLightboxModule,
	  NgxSpinnerModule,
    HttpClientModule,
    BrowserAnimationsModule,
     MatVideoModule,
    CountdownTimerModule.forRoot(),
       TranslateModule.forRoot({
           loader: {
               provide: TranslateLoader,
               useFactory: HttpLoaderFactory,
               deps: [HttpClient]
           }
       }),
       BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  focus;
  focus1;
  error = null;
  user = {};
  showresit = false;
  success = false;
  userData = {"email":"", "password":""};
  usersData = {"email":""};
  constructor(private router: Router, private httpClient: HttpClient){
   // override the route reuse strategy
   this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
   }

   this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
         // trick the Router into believing it's last link wasn't previously loaded
         this.router.navigated = false;
         // if you need to scroll back to top, here is the right place
         window.scrollTo(0, 0);
      }
  });

}

  ngOnInit() {
   this.user =  JSON.parse(localStorage.getItem('user'));
    if(this.user){

     this.router.navigate(['/home'])
  .then(() => {
    window.location.reload();
  })
  }
  }

  showRes(){

    this.showresit = true;
  }
  showLog(){

    this.showresit = false;
  }
  doRenit(){
if(this.usersData.email){
    this.success = true;
    this.error = null;
  }else{
     this.error = "Veuillez mentioner un email valide";
  }
  }
doLogin(){
  this.success = false;
if(this.userData.email && this.userData.password){
	 this.httpClient.post("/api/applications/dologin", { email: this.userData.email,password: this.userData.password }).subscribe((data:any) =>{

     if(!data.id){
    this.error = "Votre compte a été suspendu ou inexistant";
     }else{

       localStorage.setItem('user', JSON.stringify(data));


            this.router.navigate(['/home'])
         .then(() => {
           window.location.reload();
         })
     }
          /* */
         })

	/* ;*/

}else{

 this.error = "Veuillez mentioner un email et mot de passe valide";
}

}
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Route, ActivatedRouteSnapshot, UrlSegment, Params, Data } from '@angular/router';

@Component({
    selector: 'app-Results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss']
})

export class ResultsComponent implements OnInit {
  private toshow = true;

  private myresults = [];
  private  id: any;
  private usr: {};
    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;
    constructor(
    private router: Router,
    private httpClient: HttpClient,
    private route: ActivatedRoute
  ) {

    this.id =  this.route.snapshot.params.id;

   }

    ngOnInit() {
this.usr =  JSON.parse(localStorage.getItem('user'));

this.httpClient.get("/api/applications/getreponseby/"+this.id+"").subscribe((data:any) =>{

       this.myresults = data;

  console.log(data);

     });
    }

    goToExam(id){

      this.router.navigate(['/resultat-examen',id]);

    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Route, ActivatedRouteSnapshot, UrlSegment, Params, Data } from '@angular/router';

@Component({
    selector: 'app-Cours',
    templateUrl: './cours.component.html',
    styleUrls: ['./cours.component.scss']
})

export class CoursComponent implements OnInit {
    private toshow = true;
    items = [];
    cours = [];
    pageOfItems: Array<any>;
    id: any;
    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;
    constructor(
      private router: Router,
      private translate: TranslateService,
      private httpClient: HttpClient,
      private route: ActivatedRoute
  ) {

    this.id =  this.route.snapshot.params.id;
   console.log(this.id);
  }

    ngOnInit() {

       this.httpClient.get("/api/applications/examens/"+this.id+"").subscribe(( data : any ) =>{

           this.cours = data;
           this.items = data.exams;
         });


    console.log(this.items);
    }

    goToExams(id){


		 this.router.navigate(['/examens',id]);
    }
    onChangePage(pageOfItems: Array<any>) {
       // update current page of items
       this.pageOfItems = pageOfItems;
   }
}
